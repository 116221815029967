const { default: Web3 } = require("web3");
const {contractAddress,contractABI} = require("../../GlobalContext/contractData");

export async function getOwned(owner, tokenId) {
    try {
        // let web3 = new Web3("https://rpc.ankr.com/eth_goerli");
        let web3 = new Web3("https://cloudflare-eth.com");
        let contract = new web3.eth.Contract(contractABI, contractAddress);
        let totalOwned = await contract.methods.balanceOf(owner, tokenId).call();
        console.log("totalOwned", totalOwned);
        return totalOwned;
    } catch (err) {
        console.log("error in getOwned", err);
    }
}

