import React, { createContext, useState } from 'react'
import { contractAddress,contractABI } from './contractData';

export const Context = createContext()


export const Provider = ({ children }) => {
  const [account, setAccount] = useState("");
//   const [signer, setSigner] = useState();
  const [contract, setContract] = useState();
  const [web3, setWeb3] = useState();
  const [correctNetwork] = useState(1);
  const [currentNetwork, setCurrentNetwork] = useState("");
  const [owner] = useState("0xbe2a2bed0d69a57ac92aab4899ff69af06e3bf4b");
  

  const value = {
    account,
    setAccount,
    // signer,
    // setSigner,
    contract,
    setContract,
    web3,
    setWeb3,
    contractAddress,
    contractABI,
    owner,
    setCurrentNetwork,
    correctNetwork,
    currentNetwork
  }

  return <Context.Provider value={value}>{children}</Context.Provider>
}