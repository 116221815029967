import { Box, Grid, Stack, styled } from "@mui/material"
import React from "react"
import SantaForm from "./SantaForm"

const Waved = styled(Box)(({ theme }) => ({
  position: "absolute",
  bottom: theme.breakpoints.down("md") ? "-25px" : 0,
  left: 0,
  right: 0,
  height: "100px",
  background: "url('/icons/secttion-clouds.png')",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  zIndex: -1
}))
export const SantaFormSection = () => {
  return (
    <Grid container sx={{ position: "relative" }}>
      <Grid item xs={12} md={6} sx={{ order: { md: 1, xs: 2 } }}>
        <Stack
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          pt={6}>
          <img src="/icons/santaicon.png" width="60%" alt="santa-img" />
        </Stack>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          order: { md: 2, xs: 1 },
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}>
        <SantaForm />
      </Grid>
      <Waved />
    </Grid>
  )
}
