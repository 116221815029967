import { Route, Routes } from "react-router-dom"
import Home from "./pages/Home"
import SnackBar from "./component/SnackBar"
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material"
import NftDetails from "./pages/NftDetails"
import Thankyou from "./pages/ThankYou"
import { Provider } from "./GlobalContext/Context"

const theme = createTheme({
  palette: {
    primary: {
      main: "#3E207C"
    },
    background: {
      default: "#230540"
    }
  },
  shadows: [
    "none",
    "0px 1px 1px rgba(23, 23, 23, 0.10), 0px 1px 2px rgba(23, 23, 23, 0.125)",
    "0px 2px 4px rgba(23, 23, 23, 0.10), 0px 4px 6px rgba(23, 23, 23, 0.10)",
    "-2px 2px 20px 10px rgba(23, 23, 23, 0.10), -2px 2px 20px 0px rgba(23, 23, 23, 0.50)",
    "0px 3px 6px rgba(23, 23, 23, 0.20)",
    "0px 4px 4px rgba(0, 0, 0, 0.25",
    "0px 5px 10px rgba(23, 23, 23, 0.06)",
    "0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
  ],
  typography: {
    button: {
      fontWeight: 600
    },
    fontFamily: "Nunito",
    body1: {
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: 1.5,
      color: "#fff"
    },
    body2: {
      fontSize: "0.875rem",
      fontWeight: 300,
      lineHeight: 1.57,
      color: "#fff"
    },
    subtitle1: {
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: 1.75
    },
    subtitle2: {
      fontSize: "0.875rem",
      fontWeight: 500,
      lineHeight: 1.57
    },
    overline: {
      fontSize: "0.75rem",
      fontWeight: 600,
      letterSpacing: "0.5px",
      lineHeight: 2.5,
      textTransform: "uppercase"
    },
    caption: {
      fontSize: "0.75rem",
      fontWeight: 400,
      lineHeight: 1.66
    },
    h1: {
      fontWeight: 600,
      fontSize: "3.5rem",
      lineHeight: 1.375,
      color: "#fff"
    },
    h2: {
      fontWeight: 600,
      fontSize: "3rem",
      lineHeight: 1.375
    },
    h3: {
      fontWeight: 600,
      fontSize: "2.25rem",
      lineHeight: 1.375
    },
    h4: {
      fontWeight: 500,
      fontSize: "2rem",
      lineHeight: 1.375
    },
    h5: {
      fontWeight: 500,
      fontSize: "1.5rem",
      lineHeight: 1.375
    },
    h6: {
      fontWeight: 500,
      fontSize: "1.125rem",
      lineHeight: 1.375
    }
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          height: "2.4rem",
          fontSize: "12px",
          backgroundColor: "#F8F8F8",
          borderColor: "none"
        },
        input: {
          height: "0"
        },
        notchedOutline: {
          borderColor: "#E6E8F0"
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none"
        },
        sizeSmall: {
          padding: "6px 16px"
        },
        sizeMedium: {
          padding: "8px 20px"
        },
        sizeLarge: {
          padding: "11px 24px"
        },
        textSizeSmall: {
          padding: "7px 12px"
        },
        textSizeMedium: {
          padding: "9px 16px"
        },
        textSizeLarge: {
          padding: "12px 16px"
        }
      }
    }
  }
})
function App() {
  return (
    <Provider>
      <ThemeProvider theme={theme}>
        <SnackBar />
        <CssBaseline />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/nft/:address/:id" element={<NftDetails />} />
          <Route path="/greeting/:address/:id" element={<Thankyou />} />
        </Routes>
      </ThemeProvider>
    </Provider>
  )
}

export default App
