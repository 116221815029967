import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material"
import React, { useEffect, useState } from "react"
import Navbar from "../../component/Navbar"
import NftSection from "./NftSection"
import Footer from "./Footer"
import { useParams } from "react-router-dom"
import { useGetNftQuery } from "../../services/endpoints/nft.api"
import SnowFalling from "../../component/SnowFalling"
import Loader from "../../component/Loader"

const Thankyou = () => {
  const [imageURL, setImageURL] = useState("");
  const theme = useTheme()
  const { address, id } = useParams()
  const {
    data: nft,
    isLoading,
    isFetching
  } = useGetNftQuery({
    token_address: address,
    token_id: id
  })
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  const loading = isLoading || isFetching

  useEffect(()=>{
    const imgUrl = `https://testnets.opensea.io/assets/goerli/${address}/${id}`;
    setImageURL(imgUrl);
  },[])
  return (
    <>
      {loading && <Loader />}
      <Box sx={{ height: "1px", minHeight: "100vh", position: "relative" }}>
        {/* <StyledBox /> */}
        <SnowFalling />
        <Navbar />
        <Stack
          height={"100%"}
          minHeight={"calc(100vh - 100px)"}
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          px={1}>
          <Typography
            textAlign={"center"}
            maxWidth={isMobile ? "500px" : "690px"}
            fontSize={isMobile ? "40px" : "68px"}
            fontWeight={700}
            sx={{ wordBreak: "break-word", mt: "-100px" }}>
            Thank You for Your Purchase!
          </Typography>
        </Stack>
      </Box>
      <NftSection nft={nft} imageURL={imageURL} />
      <Footer />
    </>
  )
}

export default Thankyou
