import {
  Box,
  Button,
  Container,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material"
import React, { useContext, useEffect } from "react"
import { Context } from "../../GlobalContext/Context"
import { useWeb3Modal, useWeb3ModalAccount } from "@web3modal/ethers5/react"

const styles = {
  gradientText: {
    backgroundImage: "linear-gradient(28deg, #2600FC, #FA24FA)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontWeight: 700
  },
  walletRow: {
    direction: "row",
    justifyContent: "space-around",
    height: "20px",
    my: 3
  }
}
const roadMapCards = [
  {
    title: "PHASE 01",
    subTitle: "Choose",
    icon: "/zeroCircle.png",
    description:
      "Choose the one you like from our unique collections. You are spoiled for choice."
  },
  {
    title: "PHASE 02",
    subTitle: "Connect",
    icon: "/fiftyCircle.png",
    description:
      "Connect your wallet or, if you don't have one yet, create it directly from our site in a few simple steps."
  },
  {
    title: "PHASE 03",
    subTitle: "Pay",
    icon: "/hundredCircle.png",
    description:
      "Get your awesome NFT in seconds. Share your purchase on social pages and come back to visit us soon!"
  }
]
const MobileRoadmap = () => {
  const { address, chainId, isConnected } = useWeb3ModalAccount()
  const { account, setAccount } = useContext(Context)
  const { open, close } = useWeb3Modal()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const handleConnect = () => {
    open()
  }
  useEffect(() => {
    if (!account && address) {
      setAccount(address)
    }
  }, [address])
  if (!isMobile) return <></>
  return (
    <Container>
      <Typography fontSize={"25px"} fontWeight={700} textAlign={"center"}>
        Chainblock{" "}
        <Typography
          component={"span"}
          fontSize={"inherit"}
          fontWeight={"inherit"}
          sx={{
            ...styles.gradientText
          }}>
          Roadmap
        </Typography>
      </Typography>

      <Stack direction={"column"} spacing={4} my={4}>
        {roadMapCards.map((val) => (
          <Box
            sx={{
              borderRadius: "20px",
              border: "1px solid rgba(232, 232, 232, 0.36)",
              p: 3
            }}>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}>
              <Typography sx={{ ...styles.gradientText }} fontSize={"18px"}>
                {val.title}
              </Typography>
              <img width={"50px"} src={val.icon} alt="ico" />
            </Stack>
            <Typography fontSize={"20px"} fontWeight={700} my={3}>
              {val.subTitle}
            </Typography>
            <Typography fontSize={"16px"} fontWeight={300} color="#B7B4BB">
              {val.description}
            </Typography>
          </Box>
        ))}
      </Stack>
      <Stack direction={"row"} justifyContent={"center"}>
        <Button
          onClick={handleConnect}
          sx={{
            background: "linear-gradient(28deg, #2600FC, #FA24FA)",
            color: "#fff",
            borderRadius: "50px",
            px: 4,
            my: 4
          }}>
          {isConnected
            ? `${address.slice(0, 6)}...${address.slice(37, 42)}`
            : `CONNECT WALLET`}
        </Button>
      </Stack>
      <Stack {...styles.walletRow}>
        <img src={"/metamask.png"} height={"100%"} alt="img" />
        <img src={"/trust.png"} height={"100%"} alt="img" />
      </Stack>
      <Stack {...styles.walletRow}>
        <img src={"/bitgo.png"} height={"100%"} alt="img" />
        <img src={"/coinbase.png"} height={"100%"} alt="img" />
      </Stack>
      <Stack {...styles.walletRow} mb={8}>
        <img src={"/exodus.png"} height={"100%"} alt="img" />
      </Stack>
    </Container>
  )
}

export default MobileRoadmap
