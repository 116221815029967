import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material"
import React, { useEffect, useState } from "react"
import neveGrande from "../../assets/neve-grande.svg"
import Slider from "react-slick"
import NftCardHome from "../../component/NftCardHome"

const CloudyNftSection = ({ nfts }) => {
  const [nftsData, setNftData] = useState([])

  useEffect(() => {
    if (nfts?.length) {
      const nftsClone = JSON.parse(JSON.stringify(nfts))?.sort(() =>
        Math.random() > 0.5 ? 1 : -1
      )
      setNftData(nftsClone)
    }
  }, [nfts])

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const settings = {
    dots: true,
    className: "my-slider",
    infinite: true,
    autoplay: true,
    speed: 2000,
    arrows: false,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    slidesPerRow: 2,
    rows: 2,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesPerRow: 1
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesPerRow: 2
        }
      },
      {
        breakpoint: 640,
        settings: {
          rows: 1,
          slidesPerRow: 2
        }
      },
      {
        breakpoint: 500,
        settings: {
          rows: 1,
          slidesPerRow: 1
        }
      }
    ]
  }
  return (
    <>
      <Grid
        container
        sx={{ background: !isMobile ? "#fff" : "", position: "relative" }}>
        <Grid item xs={12} md={6} py={4}>
          {!isMobile && (
            <img
              src={neveGrande}
              alt="img"
              style={{
                position: "absolute",
                top: isMobile ? "-3vw" : "-2vw",
                height: isMobile ? "12vw" : "7vw"
              }}
            />
          )}
          {isMobile ? (
            <Typography
              variant="h4"
              fontWeight={800}
              sx={{
                fontSize: { sm: "24px", xs: "22px" },
                textAlign: "center",
                px: 4
              }}
              textAlign={"left"}>
              A Timeless Treasure Just for You
            </Typography>
          ) : (
            <Stack
              direction={"row"}
              my={4}
              width="100%"
              height={"100%"}
              justifyContent={"flex-end"}
              alignItems={"center"}>
              <Stack spacing={2} sx={{ maxWidth: "500px", ml: 2 }}>
                <Typography
                  color="#000"
                  variant="h4"
                  fontWeight={800}
                  sx={{
                    fontSize: { md: "32px", xs: "22px" },
                    textAlign: { md: "left", xs: "center" }
                  }}
                  textAlign={"left"}>
                  A Timeless Treasure Just for You
                </Typography>
                <Typography
                  textAlign={"left"}
                  variant="h6"
                  sx={{
                    fontSize: { md: "22px", sm: "18px", xs: "14px" },
                    fontWeight: 400
                  }}
                  color={"#000"}
                  lineHeight={1.75}>
                  Our NFTs capture the rich narrative of "A Christmas Carol,"
                  each character meticulously designed to reflect the novella's
                  enduring themes. <br />
                  Imagine gifting a piece of literary history, immortalized in
                  the blockchain, to someone special, or perhaps treating
                  yourself to a token that encapsulates the transformative
                  journey of Ebenezer Scrooge.
                </Typography>
                <Box>
                  <Button
                    variant="contained"
                    sx={{ px: 4, background: "#2478FE" }}>
                    Discover
                  </Button>
                </Box>
              </Stack>
            </Stack>
          )}
        </Grid>
        <Grid item xs={12} md={6} sx={{ margin: "auto", py: 4 }}>
          <Box sx={{ display: { xs: "none", md: "block" } }}>
            <img
              src="/icons/cloud.png"
              alt="img"
              height="70px"
              style={{ position: "absolute", top: -35, right: 20 }}
            />
          </Box>
          <Stack
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              position: "relative"
            }}>
            <div style={{ width: "90%" }}>
              <Slider {...settings}>
                {nftsData?.length &&
                  nftsData?.map((nft, index) => (
                    <div
                      style={{
                        height: "100px",
                        zIndex: 500,
                        position: "relative"
                      }}>
                      <NftCardHome nft={nft} key={`nft-${nft?._id}`} />
                    </div>
                  ))}
              </Slider>
            </div>
          </Stack>
        </Grid>
      </Grid>
      {isMobile && (
        <Stack
          px={4}
          mt={2}
          mb={4}
          direction={"column"}
          justifyContent={"space-between"}
          alignItems={"center"}>
          <Typography
            textAlign={"justify"}
            variant="h6"
            sx={{
              fontSize: { sm: "18px", xs: "14px" },
              fontWeight: 400
            }}
            lineHeight={1.75}>
            Our NFTs capture the rich narrative of "A Christmas Carol," each
            character meticulously designed to reflect the novella's enduring
            themes. Imagine gifting a piece of literary history, immortalized in
            the blockchain, to someone special, or perhaps treating yourself to
            a token that encapsulates the transformative journey of Ebenezer
            Scrooge.
          </Typography>
          <Button
            variant="contained"
            sx={{ px: 4, my: 2, background: "#2478FE", borderRadius: "50px" }}>
            Discover
          </Button>
        </Stack>
      )}
    </>
  )
}

export default CloudyNftSection
