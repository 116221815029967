import { combineReducers, configureStore } from "@reduxjs/toolkit"
import api from "./api"
import userAuthSlice, { userAuthReducer } from "./userAuthSlice"
import { commonReducer, commonSlice } from "./commonSlice"

const reducers = {
  [api.reducerPath]: api.reducer,
  [userAuthSlice.name]: userAuthReducer,
  [commonSlice.name]: commonReducer
}
const combinedReducer = combineReducers(reducers)

export const rootReducer = (state, action) => {
  return combinedReducer(state, action)
}

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware)
})
