import { useTheme } from "@emotion/react"
import {
  Button,
  Container,
  // IconButton,
  // Popover,
  Stack,
  useMediaQuery
} from "@mui/material"
import React, { useContext, useEffect } from "react"
// import MenuIcon from "@mui/icons-material/Menu"
import { useNavigate } from "react-router-dom"
import { Context } from "../GlobalContext/Context"
import Web3 from "web3"
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount
} from "@web3modal/ethers5/react"
import { useWeb3Modal } from "@web3modal/ethers5/react"
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers5/react"

// const MobileMenu = () => {
//   const [anchorEl, setAnchorEl] = React.useState(null)

//   const handleClick = (event) => {
//     setAnchorEl(event.currentTarget)
//   }

//   const handleClose = () => {
//     setAnchorEl(null)
//   }
//   return (
//     <>
//       <IconButton
//         aria-describedby={"mobile-menu"}
//         variant="contained"
//         sx={{ color: "#fff" }}
//         onClick={handleClick}>
//         <MenuIcon color="#fff" />
//       </IconButton>
//       <Popover
//         id={"mobile-menu"}
//         open={Boolean(anchorEl)}
//         anchorEl={anchorEl}
//         onClose={handleClose}
//         anchorOrigin={{
//           vertical: "bottom",
//           horizontal: "left"
//         }}>
//         <Stack
//           direction="column"
//           spacing={1}
//           sx={{
//             fontSize: { md: "18px", sm: "14px", xs: "10px" },
//             p: 1
//           }}>
//           <Button
//             variant="contained"
//             size={"small"}
//             sx={{ background: "#2478fe", borderRadius: 2 }}>
//             Connect Wallet
//           </Button>
//           <Stack
//             direction={"column"}
//             justifyContent={"center"}
//             gap={2}
//             color={"#000"}>
//             <Button variant="text" sx={{ color: "inherit" }}>
//               Home
//             </Button>
//             <Button variant="text" sx={{ color: "inherit" }}>
//               Collection
//             </Button>
//             <Button variant="text" sx={{ color: "inherit" }}>
//               Choose
//             </Button>
//             <Button variant="text" sx={{ color: "inherit" }}>
//               Roadmap
//             </Button>
//             <Button variant="text" sx={{ color: "inherit" }}>
//               Artists
//             </Button>
//           </Stack>
//         </Stack>
//       </Popover>
//     </>
//   )
// }
// 1. Get projectId
const projectId = "b3cbcae9abe3d2c54321b341c57c26d9"

// 2. Set chains
const mainnet = {
  chainId: 1,
  name: "Ethereum",
  currency: "ETH",
  explorerUrl: "https://etherscan.io",
  rpcUrl: "https://cloudflare-eth.com"
}

// 3. Create modal
const metadata = {
  name: "Christmas Chainblock",
  description: "A project of Chainblock",
  url: "https://christmas.chainblocknft.com/",
  icons: ["https://www.chainblocknft.com/images/logo/logo-svg.png"]
}

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains: [mainnet],
  projectId
})
const Navbar = () => {
  const { address, chainId, isConnected } = useWeb3ModalAccount()
  const theme = useTheme()
  const navigate = useNavigate()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const {
    account,
    setAccount,
    setWeb3,
    setContract,
    contractAddress,
    contractABI,
    setCurrentNetwork,
    correctNetwork,
    web3
  } = useContext(Context)
  const { open, close } = useWeb3Modal()
  // console.log("address", address);

  async function connectWallet() {
    // if (window.ethereum !== undefined) {
    try {
      // const provider = new ethers.providers.Web3Provider(window.ethereum)
      // const accounts = await provider.send("eth_requestAccounts", [])
      // const signer = provider.getSigner()

      // const web3 = new Web3(window.ethereum)
      // await window.ethereum.request({ method: "eth_requestAccounts" })
      // const accounts = await web3.eth.getAccounts()
      // const contract = new web3.eth.Contract(contractABI, contractAddress)
      // const network = await web3.eth.getChainId()
      // console.log("network", network)

      // setWeb3(web3)
      // setAccount(accounts[0])
      // setContract(contract)
      // setCurrentNetwork(String(network))

      // if (network != correctNetwork) {
      //   switchNetwork()
      // }
      open()
    } catch (err) {
      console.log("connectWallet error", err)
    }
    // }
    // else {
    //   alert("Please install metamask")
    // }
  }
  async function switchNetwork() {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x5" }] // goerli testnet
      })
      // let network = await web3.eth.getChainId();
      // setCurrentNetwork(network);
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    if (window.ethereum && account) {
      async function handleAccountChange() {
        try {
          await window.ethereum.on("accountsChanged", (accounts) => {
            console.log("new account: ", accounts[0])
            setAccount(accounts[0])
          })
        } catch (err) {
          console.log("handleAccountChange err:", err)
        }
      }
      async function handleNetworkChange() {
        try {
          await window.ethereum.on("chainChanged", (network) => {
            console.log("new network: ", network)
            setCurrentNetwork(network)
          })
        } catch (err) {
          console.log("handleNetworkChange err:", err)
        }
      }

      handleAccountChange()
      handleNetworkChange()
    } else {
      console.log("window.ethereum or account not found")
    }
  }, [web3])
  useEffect(() => {
    setAccount(address)
  }, [address])
  return (
    <Container component={"nav"} maxWidth="xl" sx={{ py: 4 }}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        flexWrap={"wrap"}>
        <img
          src="/icons/logo.png"
          onClick={() => {
            window.scrollTo(0, 0)
            navigate("/")
          }}
          alt="img"
          style={{ cursor: "pointer" }}
          height={isMobile ? "50px" : "100px"}
          width={"auto"}
        />
        {/* {!isMobile && (
          <Stack direction={"row"} color={"#fff"}>
            <Button variant="text" sx={{ color: "inherit" }}>
              Home
            </Button>
            <Button variant="text" sx={{ color: "inherit" }}>
              Collection
            </Button>
            <Button variant="text" sx={{ color: "inherit" }}>
              Choose
            </Button>
            <Button variant="text" sx={{ color: "inherit" }}>
              Roadmap
            </Button>
            <Button variant="text" sx={{ color: "inherit" }}>
              Artists
            </Button>
          </Stack>
        )}
        {isMobile ? (
          <MobileMenu />
        ) : ( */}
        <Button
          variant="contained"
          size={isMobile ? "small" : "medium"}
          sx={{ background: "#2478fe", borderRadius: 2 }}
          onClick={() => connectWallet()}>
          {isConnected
            ? `${address.slice(0, 6)}...${address.slice(37, 42)}`
            : `Connect Wallet`}
        </Button>
        {/* // )} */}
      </Stack>
    </Container>
  )
}

export default Navbar
