import { Box, Button, IconButton, Stack, Typography } from "@mui/material"
import React from "react"
import { useNavigate } from "react-router-dom"
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined"
const NftCard = ({ nft }) => {
  const navigate = useNavigate()
  return (
    <Box
      sx={{
        p: 2,
        borderRadius: 3,
        margin: { md: 3, xs: 1 },
        maxWidth: "289px",
        boxShadow: (theme) => theme.shadows[1],
        background: "#483D7A"
      }}>
      <Box>
        <img
          src={nft?.metadata?.image ?? "/icons/nft-sample.png"}
          height={"280px"}
          style={{ objectFit: "cover", borderRadius: "18px" }}
          width={"100%"}
          alt="img"
        />
      </Box>
      <Typography my={2}>{nft?.metadata?.name}</Typography>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography variant="subtitle" color={"#fff"}>
          Current Price
        </Typography>
        <Typography variant="body2">{nft?.price ?? 0} ETH</Typography>
        {/* <Stack>
          <Typography variant="subtitle" color={"#fff"}>
            Price
          </Typography>
          <Typography variant="body2">{nft?.price ?? 0}</Typography>
        </Stack> */}
        {/* <Stack>
          <Typography variant="subtitle" color={"#fff"}>
            Current Bid
          </Typography>
          <Typography variant="body2">0.15ETH</Typography>
        </Stack> */}
      </Stack>
      <Stack
        pt={3}
        direction={"row"}
        justifyContent={"space-evenly"}
        alignItems={"center"}>
        <Button
          variant="contained"
          sx={{
            background: "#5380EA",
            borderRadius: "50px",
            color: "#fff",
            px: 4
          }}
          onClick={() => {
            window.scrollTo(0, 0)
            navigate(`/nft/${nft?.token_address}/${nft?.token_id}`)
          }}>
          Buy
        </Button>
        <IconButton
          variant="contained"
          sx={{
            background: "rgba(239, 70, 111, 1)",
            color: "#fff",
            borderRadius: "12px",
            "&:hover": {
              background: "rgba(239, 70, 111, 0.75)"
            }
          }}>
          <FavoriteBorderOutlinedIcon />
        </IconButton>
      </Stack>
    </Box>
  )
}

export default NftCard
