import React from "react"
import Navbar from "../../component/Navbar"
import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material"
import NftDetailCard from "./NftDetailCard"
import SingleNftSliderCard from "../../component/SingleNftSliderCard"
import Footer from "./Footer"
import { useParams } from "react-router-dom"
import {
  useGetNftQuery,
  useGetNftsQuery
} from "../../services/endpoints/nft.api"
import Loader from "../../component/Loader"
import SnowFalling from "../../component/SnowFalling"
import { useTheme } from "@emotion/react"
import Slider from "react-slick"
import NftCardHome from "../../component/NftCardHome"
import MobileRoadmap from "./MobileRoadmap"
import MobileFooter from "./MobileFooter"

const NftDetails = () => {
  const { address, id } = useParams()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const isMobileSm = useMediaQuery(theme.breakpoints.down("sm"))
  const settings = {
    dots: true,
    className: "my-slider",
    infinite: true,
    autoplay: true,
    speed: 2000,
    arrows: false,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    slidesPerRow: 1,
    rows: 1,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  }
  const {
    data: nft,
    isLoading,
    isFetching
  } = useGetNftQuery({
    token_address: address,
    token_id: id
  })
  const {
    data: nftsData,
    isLoading: loadingNFts,
    isFetching: fetchingNfts
  } = useGetNftsQuery({ limit: 4 })
  const loading = isLoading || isFetching || loadingNFts || fetchingNfts
  return (
    <>
      {loading && <Loader />}
      <Box sx={{ minHeight: "100vh", position: "relative" }}>
        <SnowFalling />
        <Navbar />
        <NftDetailCard nft={nft} loading={loading} />
      </Box>
      <Container sx={{ mb: 10 }}>
        <Grid container spacing={3}>
          {nftsData?.nfts?.length &&
            (isMobile ? (
              <Grid item xs={12} md={6}>
                <Typography fontWeight={600}>
                  You might also liked them...
                </Typography>
                <Slider {...settings}>
                  {nftsData?.nfts?.map((nft) => (
                    <NftCardHome
                      nft={nft}
                      buttonColor="#5380EA"
                      backgroundColor="#8778B5"
                    />
                  ))}
                </Slider>
              </Grid>
            ) : (
              nftsData?.nfts?.map((nft) => (
                <Grid item xs={12} md={6}>
                  <SingleNftSliderCard
                    nft={nft}
                    buttonColor="#5380EA"
                    backgroundColor="#8778B5"
                  />
                </Grid>
              ))
            ))}
        </Grid>
      </Container>
      <MobileRoadmap />
      {isMobileSm ? <MobileFooter /> : <Footer />}
    </>
  )
}

export default NftDetails
