import { Container, Grid, Stack, Typography } from "@mui/material"
import React from "react"
import InfoCard from "../../component/InfoCard"

const SpiritOfChristmas = ({ nft }) => {
  return (
    <Stack sx={{ width: "100%", background: "#dee6ff" }}>
      <Container maxWidth="xl" sx={{ py: 6 }}>
        <Typography
          color={"#03062E"}
          textAlign={"center"}
          sx={{ fontSize: { md: "36px", xs: "26px" }, fontWeight: 700, pb: 4 }}>
          Why Choose a "Spirits of Christmas" NFT?
        </Typography>
        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <InfoCard
              title={"Lasting Value"}
              image={"/ethereum_5583924.png"}
              description={
                "Beyond their artistic merit, these NFTs are anticipated to appreciate over time, making them a wise investment for your digital art collection."
              }
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <InfoCard
              color={"#03062E"}
              title={"Exclusive Rarity"}
              image={"/nft_8927395.png"}
              description={`With limited editions available, owning a "Spirits of Christmas" NFT means being part of an exclusive circle of collectors.`}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <InfoCard
              title={"Deep Connection"}
              image="/neural_2103620.png"
              description={
                "Each NFT is a conversation starter, a chance to share and relive the timeless story with friends and family each Christmas."
              }
            />
          </Grid>
        </Grid>
      </Container>
    </Stack>
  )
}

export default SpiritOfChristmas
