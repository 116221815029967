import { Grid, Stack, Typography } from "@mui/material"
import React from "react"

const NftSection = ({ nft, imageURL }) => {
  return (
    <Grid container sx={{ pt: 10 }}>
      <Grid item xs={12} md={6}>
        <Stack
          direction={"row"}
          height={"100%"}
          justifyContent={"center"}
          //   alignItems={"Center"}
        >
          <Typography
            maxWidth={"500px"}
            textAlign={"center"}
            fontSize={"22px"}
            fontWeight={400}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
            tempor bibendum ipsum eu maximus. Curabitur tempor facilisis orci,
            luctus sodales massa mollis nec. Vestibulum facilisis tempor ipsum
            eu dapibus. Cras placerat tellus a iaculis ultrices. Suspendisse
            imperdiet justo at ultricies mattis.
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={12} md={6}>
        <Stack
          direction={"row"}
          justifyContent={"center"}
          sx={{ px: { xs: "10px", md: 0 }, mt: { xs: 4, md: 0 } }}>
          <a href={imageURL} target="_blank"><img
            src={nft?.metadata?.image}
            style={{ maxWidth: "450px", width: "100%", borderRadius: "15px" }}
            alt="nft-logo"
          /></a>
        </Stack>
      </Grid>
    </Grid>
  )
}

export default NftSection
