import { Container, IconButton, Stack, Typography } from "@mui/material"
import React from "react"
import "./privacy.css";

const Footer = () => {
  return (
    <Container sx={{ py: 4 }}>
      <img src="/icons/logo.png" height={"80px"} alt="logo" />
      <Stack direction={"column"} gap={3} mt={2}>
        <Stack direction={"row"} gap={1}>
          <IconButton>
          <a rel="noreferrer" href="https://www.facebook.com/chainblockgroup" target="_blank"><img src={"/icons/facebook.png"} height={"35px"} alt="facebook" /></a>
          </IconButton>
          <IconButton>
          <a rel="noreferrer" href="https://twitter.com/chainblock" target="_blank"><img src={"/twitter.png"} height={"25px"} alt="twitter" /></a>
          </IconButton>
          <IconButton style={{marginTop: "5px"}}>
          <a rel="noreferrer" href="https://www.instagram.com/chainblockgroup/" target="_blank"><img src={"/icons/instagram.png"} height={"35px"} alt="instagram" /></a>
          </IconButton>
          {/* <IconButton>
            <img src={"/icons/youtube.png"} height={"35px"} alt="youtube" />
          </IconButton> */}
        </Stack>
        <div className="privacy-policy">
          <h4 className="privacy-policy-heading">TERMS AND CONDITIONS </h4>
          <hr className="line-through" />
          <ul className="privacy-policy-list">
            <a className="terms-and-conditions-links" rel="noreferrer" target="_blank" href="https://chainblock.it/termini-e-condizioni/"><li>Terms and conditions</li></a>
            <a className="terms-and-conditions-links" rel="noreferrer" target="_blank" href="https://chainblock.it/policy-aml/"><li>AML Policy</li></a>
            <a className="terms-and-conditions-links" rel="noreferrer" target="_blank" href="https://chainblock.it/privacy-policy/"><li>Privacy Policy</li></a>
          </ul>
        </div>
        <Typography variant="caption" color={"#545C71"}>
          Copyright 2022 More. All right reserved.
        </Typography>
      </Stack>
    </Container>
  )
}

export default Footer
