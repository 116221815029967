import { Box, Stack, Typography } from "@mui/material"
import React from "react"

const InfoCard = ({ color, title, image, description }) => {
  return (
    <Box
      sx={{
        background: !color ? "#ABC0F7" : "transparent",
        borderRadius: "20px",
        height: "100%",
        minHeight: "340px",
        p: 4,
        boxShadow: (theme) => theme.shadows[4]
      }}>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography
          sx={{ fontSize: "26px", fontWeight: 700, color: color ?? "#fff" }}>
          {title}
        </Typography>
        <img width={"90px"} src={image} alt="ico" />
      </Stack>
      <Stack pt={2}>
        <Typography sx={{ fontSize: "22px", color: color ?? "#fff" }}>
          {description}
        </Typography>
      </Stack>
    </Box>
  )
}

export default InfoCard
