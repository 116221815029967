import React from "react"
import Snowfall from "react-snowfall"

const snowflake1 = document.createElement("img")
snowflake1.src = "/icons/snowflake.png"
snowflake1.style.mixBlendMode = "soft-light"
// const snowflake2 = document.createElement('img')
// snowflake2.src = '/assets/snowflake-2.jpg'

const images = [snowflake1]

const SnowFalling = ({ style }) => {
  return (
    <Snowfall
      // Applied to the canvas element
      // Controls the number of SnowFalling that are created (default 150)
      snowflakeCount={250}
      speed={[0, 2]}
      wind={[0, 2]}
      radius={[0, 30]}
      rotationSpeed={[-5, 2]}
      style={{ ...style, zIndex: -1 }}
      // Pass in the images to be used
      images={images}
    />
  )
}

export default SnowFalling
