import { createSlice } from "@reduxjs/toolkit"

export const commonSlice = createSlice({
  name: "commonSlice",
  initialState: { language: "itIT" },
  reducers: {
    updateErrorMessage(state, action) {
      state.snackBar = action.payload
    },
    updateAlertMessage(state, action) {
      state.alert = action.payload
    },
    updateGlobalLoading(state, action) {
      state.globalLoading = action.payload
    },
    updateLanguage(state, action) {
      state.language = action.payload
    }
  }
})

export const commonReducer = commonSlice.reducer
export const {
  updateErrorMessage,
  updateAlertMessage,
  updateGlobalLoading,
  updateLanguage
} = commonSlice.actions
