import {
  Box,
  Button,
  Container,
  Grid,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Context } from "../../GlobalContext/Context"
import { getOwned } from "./getOwned"
import {
  useWeb3ModalAccount,
  useWeb3ModalProvider
} from "@web3modal/ethers5/react"
import { ethers } from "ethers"
import { useDispatch } from "react-redux"
import { updateErrorMessage } from "../../services/commonSlice"

const NftDetailCard = ({ nft, loading }) => {
  const dispatch = useDispatch()
  const { address, chainId, isConnected } = useWeb3ModalAccount()
  const { walletProvider } = useWeb3ModalProvider()
  const theme = useTheme()
  const navigate = useNavigate()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const { owner, correctNetwork, contractAddress, contractABI } =
    useContext(Context)
  const [copiesLeft, setCopiesLeft] = useState(0)
  const [totalAmountToPurchase, setTotalAmountToPurchase] = useState(1)
  const [purchaseloading, setPurchaseLoading] = useState(false)

  // fetch total NFT copies owned by client
  useEffect(() => {
    if (nft?.token_id) {
      getOwned(owner, nft?.token_id)
        .then(function (totalOwned) {
          setCopiesLeft(totalOwned)
        })
        .catch(function (error) {
          console.log("error in getOwned", error)
        })
    }
  }, [nft?.token_id])
  // async function handlePurchase() {
  //   if (!!account) {
  //     try {
  //       if (currentNetwork != correctNetwork) {
  //         alert("Switch to Goerli first");
  //         return;
  //       }
  //       setPurchaseLoading(true);
  //       const totalPrice = nft.price * totalAmountToPurchase;
  //       const purchaseTrx = await contract.methods.purchaseNFT(nft.token_id, totalAmountToPurchase).send({
  //         from: account,
  //         value: web3.utils.toWei(String(totalPrice), "ether")
  //       })

  //       if (purchaseTrx.status) {
  //         navigate(`/greeting/${nft?.token_address}/${nft?.token_id}`)
  //         window.scrollTo(0, 0)
  //         setPurchaseLoading(false);

  //         // again fetch total copies
  //         await getOwned(owner, nft?.token_id);
  //         /*
  //         if(copiesLeft == 0) {
  //           // delete NFT from database if owner left with 0 copies
  //         }
  //         */
  //       } else {
  //         setPurchaseLoading(false);
  //         alert("Purchase Failed");
  //       }

  //     } catch (error) {
  //       setPurchaseLoading(false);
  //       console.log("error in handlePurchase", error);
  //     }
  //   } else { alert("Connect wallet first") }
  // }
  async function handlePurchase() {
    if (!!isConnected) {
      try {
        if (chainId != correctNetwork) {
          alert("Switch to Ethereum network first")
          return
        }
        setPurchaseLoading(true)
        const provider = new ethers.providers.Web3Provider(walletProvider)
        const signer = provider.getSigner()
        const contract = new ethers.Contract(
          contractAddress,
          contractABI,
          signer
        )
        const totalPrice = nft.price * totalAmountToPurchase
        const purchaseTrx = await contract.purchaseNFT(
          nft.token_id,
          totalAmountToPurchase,
          {
            value: ethers.utils.parseEther(String(totalPrice))
          }
        )
        const receipt = await purchaseTrx.wait()
        if (receipt && receipt.status == 1) {
          console.log("receipt", receipt)
          const totalOwned = await getOwned(owner, nft?.token_id)
          setCopiesLeft(totalOwned)

          navigate(`/greeting/${nft?.token_address}/${nft?.token_id}`)
          window.scrollTo(0, 0)
          if (totalOwned == 0) {
            // delete NFT from database if owner left with 0 copies
          }
          setPurchaseLoading(false)
        } else {
          setPurchaseLoading(false)
          alert("Purchase Failed")
        }
      } catch (error) {
        setPurchaseLoading(false)
        dispatch(
          updateErrorMessage({ type: "error", message: error?.error?.message })
        )
      }
    } else {
      alert("Connect wallet first")
    }
  }

  function showMessage() {
    if (purchaseloading) {
      return "Purchasing..."
    } else {
      return "Already Sold!"
    }
  }
  return (
    <Container maxWidth="md" sx={{ pb: 5 }}>
      <Grid container sx={{ minHeight: "calc(100vh - 164px)" }}>
        <Grid item xs={12} md={6}>
          <Stack
            height={"100%"}
            width={"100%"}
            direction={"row"}
            justifyContent={"flex-end"}
            alignItems={"center"}>
            <Box
              sx={{
                width: "100%",
                background: "rgba(255, 251, 251, 0.30)",
                fontWeight: 600,
                borderRadius: isMobile ? "15px" : "15px 0 0 15px",
                p: 4
              }}>
              <Typography variant="h4" color={"#fff"}>
                {nft?.metadata?.name}
              </Typography>
              <Typography variant="caption" color={"#fff"}>
                {nft?.metadata?.description}
              </Typography>
              <Stack direction={"row"} my={2} gap={2} alignItems={"center"}>
                <Typography variant="h5" color={"#B7B4BB"} fontWeight={600}>
                  Current Price
                </Typography>
                <Typography variant="h6" color={"#fff"} fontWeight={400}>
                  {nft?.price * totalAmountToPurchase} ETH
                </Typography>
              </Stack>
              {copiesLeft == 0 || purchaseloading ? (
                showMessage()
              ) : (
                <>
                  <Button
                    variant="outlined"
                    sx={{
                      background: "tomato",
                      color: "#fff",
                      marginRight: "10px",
                      padding: { md: "8px 20px", xs: 0 }
                    }}
                    disabled={totalAmountToPurchase == 1}
                    onClick={() => {
                      setTotalAmountToPurchase(totalAmountToPurchase - 1)
                    }}>
                    -
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      background: "#2478FE",
                      color: "#fff",
                      padding: { md: "8px 20px", xs: 0 }
                    }}
                    onClick={() => {
                      handlePurchase()
                    }}>
                    {`Buy (${totalAmountToPurchase})`}
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{
                      background: "tomato",
                      color: "#fff",
                      marginLeft: "10px",
                      padding: { md: "8px 20px", xs: 0 }
                    }}
                    disabled={totalAmountToPurchase == copiesLeft}
                    onClick={() => {
                      setTotalAmountToPurchase(totalAmountToPurchase + 1)
                    }}>
                    +
                  </Button>
                </>
              )}
              <Typography variant="h6" color={"#fff"} fontWeight={400}>
                {copiesLeft ? `(copies left: ${copiesLeft})` : null}
              </Typography>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack
            height={"100%"}
            width={"100%"}
            direction={"row"}
            justifyContent={"flex-end"}
            alignItems={"center"}>
            {loading ? (
              <Skeleton
                sx={{
                  height: 300,
                  width: "100%",
                  background: "#bebbbb85",
                  borderRadius: "15px"
                }}
                animation="wave"
                variant="rectangular"
              />
            ) : (
              <img
                src={nft?.metadata?.image}
                width={"100%"}
                style={{ borderRadius: "15px" }}
                alt="nft"
              />
            )}
            {/* </Box> */}
          </Stack>
        </Grid>
      </Grid>
    </Container>
  )
}

export default NftDetailCard
