import { Grid, Stack, Typography } from "@mui/material"
import React from "react"
import NftCard from "../../component/NftCard"
import Slider from "react-slick"
const settings = {
  dots: true,
  infinite: true,
  autoplay: true,
  centerMode: true,
  arrows: true,
  centerPadding: "0px",
  speed: 2000,
  autoplaySpeed: 2000,
  slidesToShow: 2,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 2,
        arrows: true
      }
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2,
        arrows: true
      }
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 2,
        arrows: false
      }
    },
    {
      breakpoint: 550,
      settings: {
        slidesToShow: 1,
        arrows: false
      }
    }
  ]
}
const NftSliderSection = ({ nfts }) => {
  const slicedNFTS = nfts?.length > 4 ? nfts.slice(0, 4) : nfts
  return (
    <Stack sx={{ background: "#fff", color: "#000", p: 4 }}>
      <Grid container>
        <Grid item xs={12} md={7} order={{ md: 1, xs: 2 }}>
          <Stack sx={{ px: { md: 4, xs: 0 } }}>
            <Slider {...settings}>
              {slicedNFTS?.length &&
                slicedNFTS?.map((nft) => <NftCard nft={nft} />)}
            </Slider>
          </Stack>
        </Grid>
        <Grid item xs={12} md={5} order={{ md: 2, xs: 1 }}>
          <Stack height={"100%"} direction={"column"} justifyContent={"center"}>
            <Typography
              color="#000"
              fontSize="26px"
              fontWeight={700}
              textAlign={"center"}
              sx={{
                mb: 3,
                textShadow: (theme) => theme.shadows[2]
              }}>
              How to Secure Your Piece of Christmas
            </Typography>
            <Typography color="#000" fontSize={"18px"} fontWeight={400}>
              Peruse our gallery and let the story guide you to the NFT that
              speaks to your heart. Select "Buy Now" to make it yours or enter
              your details in the form to join the holiday draw. Finalize your
              purchase or submission and embark on a festive journey with a
              timeless keepsake. <br />
              <br /> Gift a piece of Christmas that lasts forever. Let the
              "Spirits of Christmas" NFTs be your modern twist on a cherished
              holiday tradition.
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
    // <Stack
    //   direction={"column"}
    //   alignItems={"center"}
    //   sx={{ background: "#fff", color: "#000", p: 4 }}>

    //   <Typography variant="h5" textAlign={"center"} mt={4} fontWeight={600}>
    //     Esperienze Uniche con la collezione “Christmas Carol”
    //   </Typography>
    //   <Typography
    //     textAlign={"center"}
    //     color={"inherit"}
    //     maxWidth={"700px"}
    //     mt={4}>
    //     Per aggiungere un pizzico di magia natalizia, tre fortunati utenti
    //     saranno selezionati per ricevere un NFT "Christmas Carol" in omaggio!{" "}
    //     <br />
    //     Partecipa alla nostra promozione speciale semplicemente registrandoti
    //     sul nostro sito. Non perdere l'opportunità di aggiungere un tocco di
    //     festa alla tua collezione digitale.
    //   </Typography>

    //   <Container sx={{ my: 4 }}>
    //     <Slider {...settings}>
    //       {nfts?.length && nfts?.map((nft) => <NftCard nft={nft} />)}
    //     </Slider>
    //   </Container>
    // </Stack>
  )
}

export default NftSliderSection
