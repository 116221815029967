import { Grid, Stack, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import SingleNftSliderCard from "../../component/SingleNftSliderCard"
import Slider from "react-slick"
import SnowFalling from "../../component/SnowFalling"

const settings = {
  dots: true,
  infinite: true,
  autoplay: false,
  centerMode: true,
  centerPadding: "0px",
  speed: 2000,
  arrows: false,
  autoplaySpeed: 4000,
  slidesToShow: 1,
  slidesToScroll: 1,
  slidesPerRow: 1
}
const SingleNftSection = ({ nfts }) => {
  const [nftsData, setNftData] = useState([])

  useEffect(() => {
    if (nfts?.length) {
      const nftsClone = JSON.parse(JSON.stringify(nfts))?.sort(() =>
        Math.random() > 0.5 ? 1 : -1
      )
      setNftData(nftsClone?.length > 3 ? nftsClone?.slice(0, 3) : nftsClone)
    }
  }, [nfts])
  return (
    <Grid container sx={{ background: "#DEE6FF", position: "relative" }}>
      <SnowFalling />
      <Grid item xs={12} md={6} py={4}>
        <Stack
          direction={"row"}
          my={4}
          width="100%"
          height={"100%"}
          justifyContent={"center"}
          alignItems={"center"}>
          <Stack spacing={2} sx={{ maxWidth: "500px", mr: 2 }}>
            <Typography
              color="#3E207C"
              variant="h4"
              sx={{
                fontSize: { md: "28px", xs: "22px" },
                textAlign: { md: "left", xs: "center" }
              }}
              fontWeight={700}>
              This is your opportunity to be one of the select few to own a
              unique digital representation of Dickens's beloved characters.
            </Typography>
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={12} md={6} py={4}>
        <Stack
          //   direction={"row"}
          //   justifyContent={"center"}
          //   alignItems={"center"}
          px={4}>
          <Slider {...settings}>
            {nftsData?.length &&
              nftsData?.map((nft) => <SingleNftSliderCard nft={nft} />)}
          </Slider>
        </Stack>
      </Grid>
    </Grid>
  )
}

export default SingleNftSection
