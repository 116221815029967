import { Box, Stack, Typography, Button, IconButton } from "@mui/material"
import React from "react"
import { useNavigate } from "react-router-dom"
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined"
const NftCardHome = ({ nft, key }) => {
  const navigate = useNavigate()
  return (
    <Box
      key={key}
      onClick={() => {
        window.scrollTo(0, 0)
        navigate(`/nft/${nft?.token_address}/${nft?.token_id}`)
      }}
      sx={{
        p: 2,
        borderRadius: 4,
        margin: 3,
        cursor: "pointer",
        background: "#8778B5",
        boxShadow: (theme) => theme.shadows[2]
      }}>
      <Box>
        <img
          src={nft?.metadata?.image}
          height={"228px"}
          style={{ objectFit: "cover", borderRadius: "18px" }}
          width={"100%"}
          alt="img"
        />
      </Box>

      {/* <Stack
        direction={"column"}
        my={2}
        justifyContent={"space-between"}
        flexWrap={"wrap"}>
        <Typography variant="p">{nft?.metadata?.name}</Typography>
        <Typography variant="p">{nft?.price} ETH</Typography>
      </Stack> */}
      <Stack height={"50px"} overflow={"hidden"} mb={1}>
        <Typography
          my={2}
          sx={{ fontSize: { md: "16px", sm: "12px" }, lineHeight: 1.2 }}>
          {nft?.metadata?.name && nft?.metadata?.name?.length > 50
            ? nft?.metadata?.name?.slice(0, 50)
            : nft?.metadata?.name}
        </Typography>
      </Stack>
      <Stack
        direction={"row"}
        sx={{ justifyContent: { xs: "flex-start", md: "space-between" } }}>
        <Typography
          variant="body2"
          fontWeight={600}
          sx={{ display: { xs: "none", md: "inline-block" } }}>
          Current Price
        </Typography>
        <Typography variant="body2" fontWeight={600}>
          {nft?.price ?? 0} ETH
        </Typography>
      </Stack>
      <Stack direction={"row"} justifyContent={"space-evenly"} mb={"-30px"}>
        <Button
          variant="contained"
          sx={{ background: "rgba(62, 32, 124, 1)", borderRadius: "3.125rem" }}>
          Discover
        </Button>
        <IconButton
          variant="contained"
          sx={{
            background: "rgba(239, 70, 111, 1)",
            color: "#fff",
            "&:hover": {
              background: "rgba(239, 70, 111, 0.75)"
            }
          }}>
          <FavoriteBorderOutlinedIcon />
        </IconButton>
      </Stack>
    </Box>
  )
}

export default NftCardHome
