import { Grid, Stack, Typography } from "@mui/material"
import React from "react"
import "../Home/privacy.css";
const MobileFooter = () => {
  return (
    <>
      <Stack sx={{ background: "#000", px: 2, py: 1, position: "relative" }}>
        <img
          style={{ position: "absolute", right: 0, top: -32 }}
          src={"/mobile-cloud.png"}
          width={"50%"}
          alt="ico"
        />
        <Grid container>

          <Grid xs={5}>
            <img src={"/icons/logo.png"} width={"80%"} alt="ico" />
            <Stack direction={"row"} flexGrow={0} spacing={2}>
              <a rel="noreferrer" href="https://twitter.com/chainblock" target="_blank"><img src={"/twitter.png"} height={"16px"} alt="ico" /></a>
              <a rel="noreferrer" href="https://www.instagram.com/chainblockgroup/" target="_blank"><img src={"/instagram.png"} height={"16px"} alt="ico" /></a>
              <a rel="noreferrer" href="https://www.facebook.com/chainblockgroup" target="_blank"><img src={"/icons/facebook.png"} height={"16px"} alt="ico" /></a>
            </Stack>
          </Grid>
          <Grid xs={7}>
            <Stack direction={"row"} spacing={2} mt={2}>
              <Stack direction={"column"} spacing={1}>
                <a rel="noreferrer" className="terms-and-conditions-links" href="https://chainblock.it/termini-e-condizioni/" target="_blank"><Typography color={"#B7B4BB"} fontSize={"9px"}>
                  Terms and conditions
                </Typography></a>
                <a rel="noreferrer" className="terms-and-conditions-links" href="https://chainblock.it/policy-aml/" target="_blank"><Typography color={"#B7B4BB"} fontSize={"9px"}>
                  AML Policy
                </Typography></a>
                <a rel="noreferrer" className="terms-and-conditions-links" href="https://chainblock.it/privacy-policy/" target="_blank"><Typography color={"#B7B4BB"} fontSize={"9px"}>
                  Privacy policy
                </Typography></a>
              </Stack>
              {/* <Stack direction={"column"} spacing={1}>
                <Typography color={"#B7B4BB"} fontSize={"9px"}>
                  Artists
                </Typography>
                <Typography color={"#B7B4BB"} fontSize={"9px"}>
                  Choose
                </Typography>
                <Typography color={"#B7B4BB"} fontSize={"9px"}>
                  Start Selling
                </Typography>
              </Stack> */}
            </Stack>
          </Grid>
        </Grid>
        <Typography
          color={"#B7B4BB"}
          fontSize={"9px"}
          textAlign={"center"}
          pt={2}>
          Copyright © 2023 More. All Rights Reserved.
        </Typography>
      </Stack>
    </>
  )
}

export default MobileFooter
