import { Box, Button, OutlinedInput, Stack, Typography } from "@mui/material"
import React from "react"
import "./SantaForm.css";

const SantaForm = () => {
  return (
    <Box
      py={2}
      px={4}
      sx={{
        background: "#fff",
        borderRadius: "14px",
        maxWidth: "550px",
        my: { xs: 4, md: 2 },
        mx: 1
      }}>
      {/* <Typography
        variant="h5"
        textAlign={"center"}
        color={"#5380EA"}
        fontWeight={600}>
        La storia continua...con te!
      </Typography> */}
      <Typography
        className="santa-form-heading"
        variant="h5"
        textAlign={"center"}
        color={"#5380EA"}
        fontWeight={600}>
        Be Among the Select Few
      </Typography>
      {/* <Typography textAlign={"Center"} color={"#000"} py={1}>
        Diventa un eroe della nostra storia. Vuoi avere l’opportunità di vincere
        una card della nostra collezione “Christmas Carol” in omaggio? Compila
        il form e riceverai una mail con la card, se sarai uno tra i fortunati
        vincitori!
      </Typography> */}
      <Typography textAlign={"Center"} color={"#000"} py={1} style={{textAlign: "left"}} className="santa-form-description">
      Fill out the form at the bottom of the page and you could be the lucky individual to win an NFT, adding a remarkable piece to your festive celebrations.
      </Typography>

      <Stack mt={2}>
        <Typography variant="body2" fontWeight={600} color="#000">
        Name and Surname <span style={{ color: "red" }}>*</span>
        </Typography>
        <OutlinedInput placeholder={"Type your full name "} />
      </Stack>
      <Stack mt={2}>
        <Typography variant="body2" fontWeight={600} color="#000">
          Email <span style={{ color: "red" }}>*</span>
        </Typography>
        <OutlinedInput placeholder={"Type your best email address"} />
      </Stack>
      <Stack
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        pt={5}>
        <Button
          variant="contained"
          sx={{
            borderRadius: "25px",
            backgroundColor: "#5380EA"
          }}>
          Scopri se hai vinto
        </Button>
      </Stack>
    </Box>
  )
}

export default SantaForm
