import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material"
import Navbar from "../../component/Navbar"
import SnowFalling from "../../component/SnowFalling"

const HeroSection = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  return (
    <Box sx={{ minHeight: "100vh", position: "relative" }}>
      <SnowFalling />
      <Navbar />
      <Stack
        my={"50px"}
        height={"calc(100% - 100px)"}
        direction={"column"}
        justifyContent={"center"}
        alignItems={"center"}>
        <Typography
          textAlign={"center"}
          maxWidth={"750px"}
          lineHeight={1.25}
          sx={{
            wordBreak: "break-word",
            fontWeight: 700,
            fontSize: { md: "60px", sm: "48px", xs: "32px" }
          }}>
          Chainblock's song: a Christmas Story and NFT
        </Typography>
        <Typography
          sx={{ fontSize: { md: "20px", sm: "18px", xs: "14px" } }}
          fontWeight={400}
          textAlign={"center"}
          maxWidth={"750px"}
          lineHeight={1.75}
          mt={2}
          pb={5}>
          Step into a world where the spirit of Charles Dickens's "A Christmas
          Carol" is brought to life with our handcrafted "Spirits of Christmas"
          NFTs. These are not just digital assets; they are a tribute to the
          classic tale that has warmed hearts for generations. As you consider
          the perfect Christmas gift, remember that each of these NFTs carries
          with it the essence of Dickens's message of hope, redemption, and the
          joy of the season.
        </Typography>
      </Stack>
    </Box>
  )
}

export default HeroSection
