import { Container, IconButton, Stack, Typography } from "@mui/material"
import React from "react"

const Footer = () => {
  return (
    <Stack sx={{ position: "relative" }}>
      <img
        style={{
          position: "absolute",
          objectFit: "cover",
          height: "300px",
          objectPosition: "left",
          zIndex: -1
        }}
        src="/icons/wave-details.png"
        width={"100%"}
        alt="wave"
      />
      <Container sx={{ py: 4, mt: 5 }}>
        <img src="/icons/logo-forwhite.png" height={"80px"} alt="logo" />
        <Stack direction={"column"} gap={3} mt={2}>
          <Stack direction={"row"} gap={1}>
            {/* <IconButton>
              <img
                src={"/icons/whatsapp.png"}
                height={"35px"}
                style={{ mixBlendMode: "difference" }}
                alt="whatsapp"
              />
            </IconButton> */}
            <IconButton>
            <a rel="noreferrer" href="https://www.instagram.com/chainblockgroup/" target="_blank"><img
                src={"/icons/facebook.png"}
                height={"35px"}
                style={{ mixBlendMode: "difference" }}
                alt="facebook"
              /></a>
            </IconButton>
            <IconButton style={{marginTop: "5px"}}>
            <a rel="noreferrer" href="https://twitter.com/chainblock" target="_blank"><img
                src={"/twitter.png"}
                height={"25px"}
                style={{ mixBlendMode: "difference" }}
                alt="twitter"
              /></a>
            </IconButton>
            <IconButton>
            <a rel="noreferrer" href="https://www.facebook.com/chainblockgroup" target="_blank"><img
                src={"/icons/instagram.png"}
                height={"35px"}
                style={{ mixBlendMode: "difference" }}
                alt="instagram"
              /></a>
            </IconButton>
          </Stack>
          <Typography variant="caption" color={"#545C71"}>
            Copyright 2022 More. All right reserved.
          </Typography>
        </Stack>
      </Container>
    </Stack>
  )
}

export default Footer
