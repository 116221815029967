import { Container } from "@mui/material"
import HeroSection from "./HeroSection"
import CloudyNftSection from "./CloudyNftSection"
import SingleNftSection from "./SingleNftSection"
import NftSliderSection from "./NftSliderSection"
import { SantaFormSection } from "./SantaFormSection"
import Footer from "./Footer"
import {
  useGetAllPricesQuery,
  useGetNftsQuery
} from "../../services/endpoints/nft.api"
import Loader from "../../component/Loader"
import SpiritOfChristmas from "./SpiritOfChristmas"
import { useEffect, useState } from "react"
// arr1.sort(() => (Math.random() > .5) ? 1 : -1);

const Home = () => {
  const { data, isLoading, isFetching } = useGetNftsQuery()
  const [nfts, setNfts] = useState()
  const {
    data: allPrices,
    isLoading: priceLoading,
    isFetching: priceFetching
  } = useGetAllPricesQuery()

  useEffect(() => {
    if (allPrices?.length && data?.nfts?.length) {
      const nftss = data?.nfts?.map((nft) => ({
        ...nft,
        price:
          allPrices?.find(
            (filterNft) => +filterNft?.token_id === +nft?.token_id
          )?.price ?? 0
      }))
      setNfts(nftss)
    }
  }, [data, allPrices])

  const loading = isLoading || isFetching || priceLoading || priceFetching

  return (
    <>
      {loading && <Loader />}
      <Container maxWidth="xl">
        <HeroSection />
      </Container>
      <CloudyNftSection nfts={nfts} />
      <SingleNftSection nfts={nfts} />
      <SpiritOfChristmas nfts={nfts} />
      <NftSliderSection nfts={nfts} />
      <SantaFormSection />
      <Footer />
    </>
  )
}

export default Home
