import { Box, Button, Grid, Stack, Typography } from "@mui/material"
import React from "react"
import { useNavigate } from "react-router-dom"

const SingleNftSliderCard = ({
  buttonColor = "#03062E",
  backgroundColor = "rgba(83, 128, 234, 0.37)",
  color = "#03062e",
  nft
}) => {
  const navigate = useNavigate()
  return (
    <Stack
      p={2}
      sx={{
        background: backgroundColor,
        maxWidth: "640px",
        borderRadius: "15px",
        minHeight: "220px",
        height: "100%",
        // boxShadow: " -1px 3px 7px 0px rgba(0,0,0,0.53)",
        p: { md: 4, xs: 2 }
      }}>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={4}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%"
          }}>
          <img
            src={nft?.metadata?.image ?? "/icons/nft.jpg"}
            width={"100%"}
            style={{ borderRadius: "15px" }}
            alt="img"
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <Stack
            direction={"column"}
            height={"100%"}
            ml={4}
            justifyContent={"space-around"}>
            <Typography sx={{ mt: { xs: 3, md: 0 } }}>
              {nft?.metadata?.name}
            </Typography>
            <Stack
              direction={"row"}
              sx={{ gap: { md: 2, xs: 5 }, my: { xs: 2, md: 0 } }}
              alignItems={"center"}>
              {/* <Stack direction={"column"}> */}
              <Typography variant="body2" fontWeight={700}>
                Current Price
              </Typography>
              <Typography variant="caption" color={color} fontWeight={700}>
                {nft?.price ?? 0} ETH
              </Typography>
              {/* </Stack> */}
              {/* <Stack ml={3} direction={"column"}>
                <Typography variant="body2">Current Bid</Typography>
                <Typography variant="caption" color={color}>
                  0.15ETH
                </Typography>
              </Stack> */}
            </Stack>
            <Box>
              <Button
                variant="contained"
                sx={{
                  background: buttonColor,
                  color: "#fff",
                  borderRadius: "22px",
                  px: 4
                }}
                onClick={() => {
                  window.scrollTo(0, 0)
                  navigate(`/nft/${nft?.token_address}/${nft?.token_id}`)
                }}>
                Buy
              </Button>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  )
}

export default SingleNftSliderCard
